import React, { Component } from 'react';
import styled from 'styled-components';
import { isMobile } from '../../script/Commonfunctions';
import Global from '../molecule/DataCommons/global';
import Menu from '../molecule/DataCommons/menu';
import MobileMenu from '../molecule/DataCommons/mobilemenu';
import TopNavigation from '../molecule/DataCommons/topnavigation';

export default class DataCommonsHeaderInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            IsMobile: isMobile()
        };
    }

    handleResize = () => {
        const IsMobile = isMobile();
        if (IsMobile !== this.state.IsMobile) {
            this.setState({ IsMobile });
        }
    };

    componentDidMount() {
        // Add resize event listener
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        // Clean up event listener
        window.removeEventListener("resize", this.handleResize);
    }

    render() {
        const HeaderContainer = styled.div`
        .topnavigation{
            padding: 7px 0px 4px;
        }
    `

        return (

            <HeaderContainer className="headercontainer">
                <div className="topnavigation">
                    <div className="container">
                        <TopNavigation />
                    </div>
                </div>
                <div className="header-section">
                    <div className="container">
                        <Global>
                            <MobileMenu visible={this.state.IsMobile} />
                        </Global>
                    </div>
                </div>
                {/* <Affix> */}
                <div className="mainmenu" style={{ 'display': this.state.IsMobile ? 'none' : 'block' }}>
                    <div className="container">
                        <Menu />
                    </div>
                </div>
                {/* </Affix> */}
            </HeaderContainer>

        );
    }
}
