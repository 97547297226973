import {
  AppstoreOutlined,
  CaretRightOutlined,
  LinkOutlined,
  UnorderedListOutlined
} from "@ant-design/icons";
import { Button, Col, Collapse, Dropdown, Menu, message, Row, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { routeDataCommonsConstants } from "../../../../helper/Common/RoutePathConstants.js";
import { Topic } from "../../state";
import { ShowOverviewToggler } from "../goals/GoalTable";
import { IndexTable } from "../goals/ViewIndex";
import Indicator from "./Indicator";
import { FilterParams, GridHeader, OpenKeysContext, SubAreacard } from "./ThematicAreaView";
import ThematicOverview from "./ThematicOverview";

const { Panel } = Collapse;
interface DataType {
  indicator_name: string;
  indicator_codes: string[];
}

const AreaTable: React.FC<{
  store: object | undefined;
  filterParams: FilterParams | undefined;
  // columns: ColumnsType<DataType>;
  area: Topic;
  opened?: boolean;
  filtredData: null;
  indicatorsCount: number;
}> = ({ opened, filterParams, store, area, filtredData, indicatorsCount }) => {
  const [toggleCharts, setToggleCharts] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(false);
  const { showOverview, setShowOverview, openKeys, setOpenKeys, backUrl, setBackUrl } = useContext(OpenKeysContext);
  
  const location = useLocation();
  const [messageApi, contextHolder] = message.useMessage();



  const columns: ColumnsType<DataType> = [
    {
      title: "Indicator",
      dataIndex: "indicator_name",
      key: "name",
      render: (text, record) => {
        let link = ""
        if (typeof record.key != "undefined") {
          link = `#explore+${record.key}`
        } else {
          const parent = findSubTheme([area], record.id);
          link = `#explore+${parent.id}+${record.id}`
        }
        record.link = link;
        return <Link to={link} onClick={()=>setBackUrl(location.pathname)}>{text}</Link>;
      },
    },
    {
      title: "Agency",
      dataIndex: "agency",
    },
    {
      // title: 'Action',
      dataIndex: 'operation',
      key: 'operation',
      width: 28,
      render: (text, record) => {
        return <>
          <Dropdown overlay={
              <Menu>
                  {/* <Menu.Item key="1"><DownloadOutlined /> Download</Menu.Item> */}
                  <CopyBtn item={record} />
              </Menu>
          } 
          trigger={["click"]}
          >
            <a>
              <span className="material-icons">more_vert</span>
            </a>
          </Dropdown>
      </>},
    },
  ];

  
  const PanelHeader = ({ item, onSubthemeClick }) => {
    
    return (
      <SubThemeHeader>
        <Row gutter={8}>
          <Col span={19}>
            <Link to={'#explore+'+item.id} onClick={(e)=>onSubthemeClick(e, '#explore+'+item.id)}><strong>{item?.label || item?.indicator_name}</strong>
            {item?.children?.length && ` (${item?.children?.length})`}
            </Link>
          </Col>
          <Col>
            <div className="filters">
              <Dropdown 
                overlay={
                  <Menu>
                      <CopyBtn item={{link: '#explore+'+item.id}} />
                  </Menu>
                } 
                trigger={["click"]}
              >
                <a>
                  <span className="material-icons">more_vert</span>
                </a>
              </Dropdown>
            </div>
          </Col>
        </Row>
      </SubThemeHeader>
    );
  };

  const CopyBtn = ({item}) => {
    return <Menu.Item onClick={() => handleCopy(item.link)} key="2"><LinkOutlined /> Copy sharable link</Menu.Item>
  }

  const handleCopy = async (hash) => {
    const link = `${window.location.origin}${routeDataCommonsConstants.THEMATIC_AREAS}/${area.id}${hash}`
    message.destroy();
    try {
      await navigator.clipboard.writeText(link);
      messageApi.open({
        type: 'success',
        content: 'Copied!',
      });
    } catch (error) {
      console.error("Failed to copy text:", error);
    }
  };

  const findSubTheme = (nodes, childKey) => {
    for (const node of nodes) {
      if (node.children && node.children.length > 0) {
        for (const child of node.children) {
          if (child.children && child.children.length > 0) {
            for (const grandchild of child.children) {
              if (grandchild.id === childKey) {
                return child;
              }
            }
          }
        }
      }
    }
    return null;
  };
  
  const collectIds = (data) =>
    data.reduce(
      (ids, record) => [
        ...ids,
        record.id,
        ...(record.children ? collectIds(record.children) : []),
      ],
      []
  );

  const toggleAll = () => {
    setToggleCharts(!toggleCharts);
    setExpanded(!toggleCharts);
    // if(!expanded)
      // setExpanded(!expanded);
    const allKeys = filtredData?.length ? [...collectIds(filtredData), area.id] : [area.id];
    setOpenKeys(allKeys);
  };

  const collapseAll = () => {
    setToggleCharts(!toggleCharts);
    setExpanded(!toggleCharts);
    const allKeys = !toggleCharts&&filtredData?.length ? filtredData.map((record) => record.id) : [area.id];
    setOpenKeys(allKeys);
  };

  const expandAll = () => {
    setExpanded(!expanded);

    if(!expanded && toggleCharts){
      setToggleCharts(!toggleCharts);
    }
    const allKeys = (!expanded && filtredData?.length) ? [...filtredData.map((record) => record.id), area.id] : [area.id];
    setOpenKeys(allKeys);
  }

  const onExpand = (expanded, record) => {
    setOpenKeys((keys) =>
      expanded ? [...keys, record.id] : keys.filter((key) => key !== record.id)
    );
  };

  const collectKeys = (data, expandedKeys = []) => {
    for (const item of data) {
      if (item.children) {
        expandedKeys.push(item.key);
        collectKeys(item.children, expandedKeys);
      }
    }
    return expandedKeys;
  };

  const onHeaderClick = () => {
    setShowOverview(!showOverview);
  };

  const onSubthemeClick = (e, url) => {
    setBackUrl(location.pathname)
    // history.push(routeDataCommonsConstants.THEMATIC_AREAS);
    // e.preventDefault()
  }

  const expandedBlock = openKeys.includes(area.id)
  return (
    <AreaCollapse
      collapsible="icon"
      expandIcon={({ isActive }) => (
        <Tooltip placement="top" title={`Expand`}>                                                                                                                                                                                       
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        </Tooltip>
      )}
      defaultActiveKey={opened ? [area?.id] : []}
      onChange={(keys) => onExpand(!openKeys.includes(area.id), area)}
      activeKey={openKeys}
    >{contextHolder}
      <Panel
        key={area?.id}
        header={
          <GridHeader>
            <Row gutter={8}>
              <div>
                <span>
                  <Tooltip
                    placement="top"
                    title={`Total ${area.label} indicators`}
                  >
                    <Col>
                      <img src={`${area?.icon}`} />
                    </Col>
                    <Col span={19}>
                      <strong className="font-l">{area?.label}</strong>
                    </Col>
                  </Tooltip>
                </span>
                <Col>
                  <span onClick={onHeaderClick}>
                    <ShowOverviewToggler showOverview={!!showOverview} />
                  </span>
                </Col>
              </div>
              <div className="filters">
                <Col style={{ display: "inline-flex" }}>
                  <span style={{ fontSize: "12px" }}>
                    Found&nbsp;<strong>{indicatorsCount}</strong>&nbsp;item(s)
                  </span>
                </Col>
                {!showOverview &&
                <Col>
                  {!toggleCharts ? (
                    <Tooltip placement="top" title="List view">
                      <ExpandAllBtn 
                        // disabled={!expanded} 
                        onClick={() => toggleAll()}
                      >
                        <AppstoreOutlined/>
                      </ExpandAllBtn>
                    </Tooltip>
                  ) : (
                    <Tooltip placement="top" title="Chart view">
                      <ExpandAllBtn 
                        // disabled={!expanded} 
                        onClick={() => collapseAll()}
                      >
                      <UnorderedListOutlined/>
                    </ExpandAllBtn>
                    </Tooltip>
                  )}
                </Col>
                }
                {!showOverview &&
                <Col>
                  {
                    <ExpandAllBtn disabled={!expandedBlock} onClick={() => expandAll()}>
                      <Tooltip
                        placement="top"
                        title={
                          !expanded ? "Expand all" : "Collapse all"
                        }
                      >
                        {!expanded ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M10 18.3334L5 13.3334L6.1875 12.1459L10 15.9584L13.8125 12.1459L15 13.3334L10 18.3334ZM6.20833 7.83335L5 6.66669L10 1.66669L15 6.66669L13.7917 7.83335L10 4.04169L6.20833 7.83335Z"
                              fill="currentColor"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M6.16667 18.3334L5 17.1667L10 12.1667L15 17.1667L13.8333 18.3334L10 14.5L6.16667 18.3334ZM10 7.83335L5 2.83335L6.16667 1.66669L10 5.50002L13.8333 1.66669L15 2.83335L10 7.83335Z"
                              fill="currentColor"
                            />
                          </svg>
                        )}
                      </Tooltip>
                    </ExpandAllBtn>
                  }
                </Col>
                }
              </div>
            </Row>
          </GridHeader>
        }
        
      >
        {!showOverview && (
          <HeaderRow>
            <Col span={21}>Item</Col>
            <Col span={3}>Partner</Col>
          </HeaderRow>
        )}
        {!showOverview ? (
            filtredData?.map(
                (item) =>
                  !(item.id + "").includes("-topic") && (
                    <NestedCollapse
                    key={item.id}
                      collapsible="icon"
                      expandIcon={({ isActive }) => (
                        <Tooltip placement="top" title={`Expand`}>
                          <CaretRightOutlined rotate={isActive ? 90 : 0} />
                        </Tooltip>
                      )}
                      onChange={(keys) => onExpand(!openKeys.includes(item.id), item)}
                      activeKey={openKeys}
                      // defaultActiveKey={opened ? [area?.id] : []}
                    >
                      <Panel key={item?.id} header={<PanelHeader item={item} onSubthemeClick={onSubthemeClick}/>}>                                                                          
                        <IndicatorTable 
                            color={area.color}
                            columns={columns} 
                            expandable={
                            {
                            expandedRowRender: record => {
                            return record.indicator_codes?.length && <SubAreacard>
                                <Indicator placeDcid={filterParams?.location[0] || "Earth"} store={store} name={record.indicator_name} code={record.indicator_codes[0]} limit={1} />
                            </SubAreacard>
                            },
                            expandIconColumnIndex: columns.length-1,
                            expandIcon: ({ expanded, onExpand, record }) => <ExpandIcon onClick={(e) => onExpand(record, e)} className={`material-icons ${expanded && `expanded`}`}>chevron_right</ExpandIcon>,
                            rowExpandable: record => record.indicator_codes?.length,
                            onExpand,
                            expandedRowKeys: openKeys
                            }
                            }
                            rowKey={'id'}
                            dataSource={item.children} 
                            pagination={item.children.length > 15 ? { pageSize:15 } : false}
                        />
                      </Panel>
                    </NestedCollapse>
                  )
              )
        ) : (
          <ThematicOverview area={area} />
        )}
      </Panel>
    </AreaCollapse>
  );
};


export default AreaTable;

export const IndicatorTable = styled(IndexTable)`
  .ant-table {
    .ant-table-expanded-row {
      .ant-row {
        padding-top: 4px!important;
      }
    }
    .-dc-indicator-chart {
      padding-right: 6px!important;
    }
    table-layout: fixed;
    border-radius: 8px 8px 0 0!important;
    border-bottom: solid 1px #D9D9D9;
  }
  .ant-table-row-expand-icon-cell {
    text-align: right;
    padding: 0!important;
    max-width: 20px!important;
  }
  .ant-table-tbody>tr>td {
    padding: 5px 3px;
    border-bottom: none;
  }
  .ant-collapse-content {
    border-top: none!important;
  }
  .ant-table-row {
    border-color: #fff!important;

    .ant-table-cell:first-child {
      width: 87.5%;
      padding-left: 48px;
      position: relative;
      &:before {
        display: inline-block;
        content: " ";
        background: ${({ color }) => color || "#000"};
        width: 8px;
        height: 8px;
        position: absolute;
        top: 11px;
        left: 26px;
        border-radius: 6px;
      }
    }
    .ant-table-cell:last-child {
      text-align: center;
      padding: 0;
    }
  }
  a {
    color: #000;
  }
`;

const NestedCollapse = styled(Collapse)`
  border-bottom: none !important;
  .ant-collapse-item {
    border-top: none;
    border-bottom: none;
  }
  .ant-collapse-expand-icon {
    height: 26px !important;
  }
  .ant-collapse-content {
    border-top: none;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0 0 0 16px;
  }
  .ant-table-thead {
      display: none;
  }
  .material-icons {
    font-size: 20px;
  }
`;
const SubThemeHeader = styled.div`
  > .ant-row {
    display: flex;
    justify-content: space-between;
  }
  .ant-collapse-expand-icon {
    height: 26px !important;
  }
`;
const HeaderRow = styled(Row)`
  margin-left: 43px;
  margin-top: -13px;
  margin-bottom: 10px;
  border-bottom: solid #d9d9d9 1px;
`;
export const ExpandIcon = styled.span`
  font-size: 20px !important;
  margin-right: 0 !important;
  transition: transform 0.3s ease;
    transform: rotate(90deg);
  cursor: pointer;
  &.expanded {
    transform: rotate(-90deg);
  }
`;
const AreaCollapse = styled(Collapse)`
  .ant-collapse-header {
    padding: 3px 0 3px 0 !important;
    .ant-row {
      padding-left: 38px;
      margin: 0 0 !important;
      transition: all 0.3s;
      border-color: #d3d3d3;
      line-height: 28px;
    }
    &[aria-expanded="false"] .ant-row {
      border-color: #fff;
      margin: 0 0 !important;
    }
  }
  .ant-collapse-expand-icon {
    padding-left: 18px;
    position: absolute;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
  }
`;
const ExpandAllBtn = styled(Button)`
  border: none;
  min-width: 14px;
  padding: 0;
  text-align: center;
  font-size:15px!important;
  &.ant-btn svg {
    width: 15px !important;
    margin-right: 0;
  }
  &.ant-btn[disabled]{
    background: transparent;
  }
  .anticon-appstore, 
  .anticon-unordered-list {
    margin: 1px 0 -1px!important;
  }
  &:active, 
  &:focus {
    color: #000000d9;
  }
`;
