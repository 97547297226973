import { Input } from "antd";
import MenuItem from "antd/lib/menu/MenuItem";
import Table, { ColumnsType } from "antd/lib/table";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { FulfillResponse } from "../../utils/types";
import { GridTableWrapper } from "../areas/ThematicAreaView";

const columns: ColumnsType<DataType> = [
  {
    title: "Indicator",
    dataIndex: "title",
    key: "name",
    render: (text, record) => {
      return <Link to={`${record.url}`}>{text}</Link>;
    },
  },
  // {
  //   title: "Type",
  //   dataIndex: "type",
  // },
];

export const IndexTable = styled(Table)`
  .ant-table {
    border-radius: 8px !important;
    overflow: hidden;
  }
  .rc-pagination .ant-pagination-prev,
  .ant-pagination .ant-pagination-prev,
  .ant-pagination .ant-pagination-next {
    padding: 0;
  }
  .ant-table-expanded-row td.ant-table-cell {
    padding: 0 0;
  }
`;

const ViewIndex: React.FC<{ fulfillResponse: FulfillResponse; buildTileHierarchy: () => void }> = ({ fulfillResponse, buildTileHierarchy }) => {
  const location = useLocation();
  const url = [location.pathname, location.search].join("");

  const tmp = fulfillResponse?.config?.categories ? fulfillResponse?.config?.categories[0].blocks.map((t, i) => ({ ...t, key: i, url: url + "#" + i })) : [];
  const [items, setItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    setItems(tmp?.filter((item: MenuItem) => item.title));
  }, []);
  const search = (value: string) => {
    setSearchTerm(value.target.value);
    if (value.target.value.length === 0) {
      setItems(tmp?.filter((item: MenuItem) => item.title));
    } else {
      const filtered = tmp?.filter((item) => {
        const indicator = item?.title?.toLowerCase() ?? "";
        return indicator.includes(value.target.value);
      });
      setItems(filtered);
    }
  };
  return (
    <>
      <GridTableWrapper>
        <Input value={searchTerm} onChange={search} placeholder="Search" />
        <div className="results-subheader">
          Found <strong>{items.length}</strong> indicator(s)
        </div>
        <IndexTable columns={columns} dataSource={items} />
      </GridTableWrapper>
    </>
  );
};

export default ViewIndex;
