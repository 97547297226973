import { Carousel } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const GeneralCarousel: React.FC = ({ children, initialSlide, exploreLink }) => {

    useEffect(()=>{
        if(initialSlide)setCurrentIndex(initialSlide)
    }, [initialSlide])

    const [currentIndex, setCurrentIndex] = useState<number>(0)
    const childCount = children ? React.Children.count(children.filter(a=>!!a)) : 0;

    const handleBeforeChange = (from, to) => {
        setCurrentIndex(to);
      };

    return <ChartCarouselContainer>
      {!!childCount && 
        <div className="index-text">
          {currentIndex+1} of {childCount} Item(s)
          {exploreLink && <Link to={exploreLink}>
          <svg style={{marginLeft:'8px'}} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g clip-path="url(#clip0_35250_81252)">
              <rect width="20" height="20" transform="translate(0.0556641 0.103027)" fill="white"/>
              <path d="M17.5555 15.1029H1.72217V16.7696H17.5555V15.1029ZM15.8888 8.43628V11.7696H3.38883V8.43628H15.8888ZM16.7222 6.76961H2.5555C2.09717 6.76961 1.72217 7.14461 1.72217 7.60295V12.6029C1.72217 13.0613 2.09717 13.4363 2.5555 13.4363H16.7222C17.1805 13.4363 17.5555 13.0613 17.5555 12.6029V7.60295C17.5555 7.14461 17.1805 6.76961 16.7222 6.76961ZM17.5555 3.43628H1.72217V5.10295H17.5555V3.43628Z" fill="#323232"/>
            </g>
            <defs>
              <clipPath id="clip0_35250_81252">
                <rect width="20" height="20" fill="white" transform="translate(0.0556641 0.103027)"/>
              </clipPath>
            </defs>
          </svg>
          </Link>
          }
        </div>
      }
      <Carousel 
        lazyLoad="ondemand"
        beforeChange={handleBeforeChange} 
        nextArrow={<CustomPrevArrow/>} 
        prevArrow={<CustomNextArrow/>} 
        arrows
        dots={false}
        initialSlide={initialSlide||0}
        // adaptiveHeight
      >
        {children}
      </Carousel>
    </ChartCarouselContainer>
}

export default GeneralCarousel;

export const CustomPrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style}}
        onClick={onClick}
      >
         <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path d="M8.0876 5L6.9126 6.175L10.7293 10L6.9126 13.825L8.0876 15L13.0876 10L8.0876 5Z" fill="black"/>
        </svg>
      </div>
    );
  };
  
  export const CustomNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style }}
        onClick={onClick}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path d="M13.0876 6.175L11.9126 5L6.9126 10L11.9126 15L13.0876 13.825L9.27093 10L13.0876 6.175Z" fill="black"/>
        </svg>
      </div>
    );
  };
  export const ChartCarouselContainer = styled.div`
    background: #fff;
    border-radius:8px;
    margin: -6px 0;
    .ant-btn {
      &[disabled] {
          opacity: 40%;
        svg {
        }
      }
      svg {
        width:24px!important;
      }
    }
    .ant-carousel 
    {
      .slick-slider {
        position: static;
      }
      .slick-arrow.slick-prev,
      .slick-arrow.slick-next {
        background:#fff;
        top: 20px!important;
      }
    }
    .index-text {
      background: #fff;
      padding: 0 5px;
      font-size: 13px;
      line-height: 26px;
      position: absolute;
      top: 26px;
      z-index: 5;
      right: 50px;
    }
  
    datacommons-map::part(source-separator) {
      display: none;
    }
    datacommons-map::part(source-show-metadata-link) {
      display: none;
    }
  
    datacommons-line::part(source-separator) {
      display: none;
    }
    datacommons-line::part(source-show-metadata-link) {
      display: none;
    }
  
    datacommons-bar::part(source-separator) {
      display: none;
    }
    datacommons-bar::part(source-show-metadata-link) {
      display: none;
    }
  
    datacommons-highlight::part(source-separator) {
      display: none;
    }
    datacommons-highlight::part(source-show-metadata-link) {
      display: none;
    }
  `