import { CaretRightOutlined } from "@ant-design/icons";
import { Badge, Col, Collapse, Row, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import MiniSearch from "minisearch";
import { useContext, useEffect, useRef, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { GroupedArticles } from "../../../../hooks/useArticles";
import { Topic, useStoreActions, useStoreState } from "../../state";
import { EARTH_PLACE_DCID, ROOT_TOPIC, WEB_API_ENDPOINT } from "../../utils/constants";
import { FulfillResponse } from "../../utils/types";
import ChartCarousel from "../areas/ChartCarousel";
import { FilterParams, GridHeader } from "../areas/ThematicAreaView";
import { getGoalTargetIndicator, Spinner } from "../countries/CountriesContent";
import { ContentCard } from "../shared/components";
import { sdgColors } from "../shared/goals/GoalOverview";
import GoalTableOverview from "./GoalTableOverview";
import { OpenKeysContext } from "./Goals";

const { Panel } = Collapse;

interface DataType {
  indicator_name: string;
  indicator_codes: string[];
}

const GoalTable: React.FC<{
  store: object | undefined;
  filterParams: FilterParams | undefined;
  columns: ColumnsType<DataType>;
  area: Topic;
  opened?: boolean | number;
  placeDcids?: string[];
  localFulfillResponse?: FulfillResponse;
  setCarouselProps?: object | undefined;
  varToTopics: object;
  dataStories: false | GroupedArticles | undefined | null;
  infographics: false | GroupedArticles | undefined | null;
}> = ({ columns, opened, filterParams, store, area, placeDcids, localFulfillResponse, setCarouselProps, varToTopics, dataStories, infographics }) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState<[]>([]);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [toggleCharts, setToggleCharts] = useState<boolean>(false);
  const [sortDesc, setSortDesc] = useState<boolean>(false);
  const isMounted = useRef(true);
  const [activeKey, setActiveKey] = useState(null);
  const [nestedData, setNestedData] = useState(null);
  const [allFlatData, setAllFlatData] = useState(null);
  const [filtredData, setFiltredData] = useState(null);
  const [indicatorsCount, setIndicatorsCount] = useState(0);
  const { openKeys, setOpenKeys, showOverview, setShowOverview } = useContext(OpenKeysContext);
  const fetchTopicFulfillment = useStoreActions((a) => a.fetchTopicFulfillment);
  const fulfillmentsById = useStoreState((s) => s.fulfillments.byId);
  const [isMiniSearchReady, setIsMiniSearchReady] = useState<boolean>(false);

  const miniSearchRef = useRef(
    new MiniSearch({
      fields: ["label", "type"],
      storeFields: ["key", "label", "parentId"],
      idField: "key",
    })
  );

  const handleChangeKeys = (keys) => {
    setOpenKeys(keys);
  };

  const goal = area?.key.split("_")[1];

  useEffect(() => {
    if (!localFulfillResponse?.config?.categories || !varToTopics) return;
    // setLoaded(false)
    isMounted.current = true;
    let dcids = area.children
      .map((a) => {
        return a.children.map((b) => b.key);
      })
      .flat(1);

    const topics = localFulfillResponse?.config.categories?.[0].blocks.map((a, i) => {
      return {
        label: a.title, //a.columns[0].tiles[0].title,//
        fulfillId: i,
        key: a.columns?.[0]?.tiles?.[0]?.statVarKey,
        type: "indicator",
      };
    });
    if (!topics?.length) {
      console.log("topics missed");
      return;
    }
    if (dataStories && filterParams?.location?.length == 1 && filterParams?.location.includes(EARTH_PLACE_DCID)) {
      // return;
      // fetchArticles(dcids || [`${area?.key}`]).then((resp) => {
      // if (isMounted.current) {
      // setDataStories(resp);
      let d = wrapNestedData({ infographics, dataStories: dataStories, indicators: area?.children, topics, varToTopics });
      setNestedData(d.data);
      setAllFlatData(d.flatData);
      if (!isMiniSearchReady) miniSearchRef.current.addAll(d.flatData);
      // setFiltredData(d.data)
      setLoaded(true);
      setIsMiniSearchReady(true);
      // }
      // });
    } else {
      let d = wrapNestedData({ infographics: [], dataStories: [], indicators: area?.children, topics, varToTopics });
      setNestedData(d.data);
      setAllFlatData(d.flatData);
      // setIndicatorsCount(d.count)
      // setFiltredData(d.data)
      if (!isMiniSearchReady) miniSearchRef.current.addAll(d.flatData);
      setLoaded(true);
      setIsMiniSearchReady(true);
    }
    return () => {
      isMounted.current = false;
    };
  }, [localFulfillResponse?.config?.categories, varToTopics, area, dataStories]);

  useEffect(() => {
    if (!openKeys && opened) {
      let allKeys = store.rootTopics.map((a) => a.topicDcid);
      setOpenKeys(allKeys);
      // setOpenKeys([area.key])
    }
    // if(openKeys && openKeys.includes(area.key)) setShowOverview(false)
  }, [opened]);

  useEffect(() => {
    if (loaded && nestedData) {
      // if(filterParams?.que) console.log(miniSearchRef.'current.search(''dc/topic/sdg_1.1'),'tapa')
      if (filterParams) nestedSearch(filterParams);
    }
    if (filterParams?.que && showOverview) {
      setShowOverview(false);
    }
  }, [loaded, filterParams?.que, nestedData]);

  const wrapNestedData = ({ infographics, dataStories, indicators, topics, varToTopics }) => {
    let count = 0;
    let d = indicators.map((a) => {
      return {
        ...a,
        children: a.children.map((b) => {
          let childs = [];

          // Infographics
          let infos = infographics
            .filter((c) => {
              return `${ROOT_TOPIC}_${c.key}` == b.key;
            })
            .map((c, i) => ({ ...c, type: "infographic", title: c.Indicator, label: c.Indicator, key: c.key + "_" + i }));
          childs = childs.concat(infos);

          // data stories
          if (dataStories?.[b.key]) {
            childs = childs.concat(
              Object.keys(dataStories[b.key]).map((k) => ({
                ...dataStories[b.key][k],
                key: k + "_" + b.key,
                title: dataStories[b.key][k].name,
                label: dataStories[b.key][k].name,
                type: "dataStory",
              }))
            );
          }

          // topics
          if (topics?.length && varToTopics) {
            let tmpChilds = topics.filter((t) => hasIntersection(t.key, varToTopics[b.key])).map((a, i) => ({ ...a, title: a.label, tileId: i }));
            // unique by title
            tmpChilds = Array.from(new Map(tmpChilds.map((item) => [item.title, item])).values()).filter(
              (item) =>
                !tmpChilds.find((other) => {
                  let title = item.title || item.label;
                  let otherTitle = other.title || other.label;
                  return otherTitle !== title && otherTitle.includes(title);
                })
            );

            childs = childs.concat(tmpChilds);
          }
          count += childs.length;
          return { ...b, children: childs };
        }),
      };
    });

    let tmpFlatData = flattenNestedData(d, area.key);

    return { data: d, count, flatData: tmpFlatData };
  };

  const expandAll = () => {
    setToggleCharts(!toggleCharts);
    if (filtredData?.length) expandKeys(filtredData);
    else setExpandedRowKeys([]);
  };

  const expandKeys = (data) => {
    const allKeys = data?.length
      ? collectKeys(data) //?.map((record) => record.key)
      : [];
    setExpandedRowKeys(allKeys);
    // setOpenKeys([...new Set(allKeys.concat(openKeys))])
  };

  const collectKeys = (data, expandedKeys = []) => {
    for (const item of data) {
      if (item.children) {
        expandedKeys.push(item.key);
        collectKeys(item.children, expandedKeys);
      }
    }
    return expandedKeys;
  };

  const collapseAll = () => {
    setToggleCharts(!toggleCharts);
    setExpandedRowKeys([]);
  };

  const onExpand = (expanded, record) => {
    let keys = [];
    if (expanded) {
      keys = [...expandedRowKeys, record.key];
    } else {
      keys = expandedRowKeys?.length ? [...expandedRowKeys.filter((i, k) => i != record.key)] : [];
    }

    setExpandedRowKeys(keys);
  };

  const nestedSearch = (params: FilterParams) => {
    let queFiltred;
    if (params.que) {
      queFiltred = miniSearchRef.current.search(params.que, { prefix: true, fuzzy: 0.2 });
      queFiltred = queFiltred.filter((a) => a.score * 3 > queFiltred[0].score);
    }
    let { count, results } = findAllNestedMatches(
      nestedData,
      params,
      queFiltred?.map((a) => a.label)
    );
    setFiltredData(results);
    setIndicatorsCount(count);

    if (params.que || params.location?.[0] != "Earth") expandKeys(results);
    setLoaded(true);
  };

  const filterNestedData = (nestedData, ids) => {
    return nestedData
      .filter((item) => ids.find((id) => item.key.includes(id)))
      .map((item) => ({
        ...item,
        children: item.children ? filterNestedData(item.children, ids) : [],
      }));
  };

  const findAllNestedMatches = (items, searchTerm, queFiltred) => {
    if (!items) return false;
    let count = 0;
    const results = items
      .filter((item) => {
        let topicCheck = item.key.includes(ROOT_TOPIC) ? searchTerm.topics.find((a) => a.includes(item.key) || item.key.includes(a)) : true;
        return topicCheck;
      })
      .map((item) => {
        let label = item.label || item.name || item.title;
        if (item.children) {
          // Recursively search children
          const childMatches = findAllNestedMatches(item.children, searchTerm, queFiltred);
          if (childMatches.results.length > 0) {
            count += childMatches.count; // Add count from child matches
            return { ...item, children: childMatches.results || [] };
          }
        } else if (!queFiltred || queFiltred?.includes(label)) {
          // else if ( label.toLowerCase().includes(searchTerm.que.toLowerCase()) ) {
          count += 1; // Increment count for last-level match
          return { ...item };
        }
        return null; // No match
      })
      .filter(Boolean);

    return { results, count };
  };

  const flattenNestedData = (data, parentId = null) => {
    const flatData = [];
    data.forEach((item) => {
      flatData.push({
        ...item,
        parentId, // Keep a reference to the parent
      });

      if (item.children) {
        flatData.push(...flattenNestedData(item.children, item.key));
      }
    });

    return flatData;
  };

  const onHeaderClick = (e) => {
    setShowOverview(!showOverview);
    e.preventDefault();
  };

  const onTitleClick = (e) => {
    let keys = openKeys.includes(e) ? openKeys.filter((a) => a != e) : [...openKeys, e];
    handleChangeKeys(keys);
  };

  const onArrowClick = (key) => {
    setActiveKey(key);
  };

  if (!loaded)
    return (
      <ContentCard>
        <Spinner />
      </ContentCard>
    );
  if (!filtredData?.length || indicatorsCount == 0) return false;
  // return <ContentCard className="not-found">No data found</ContentCard>

  return (
    <AreaCollapse
      collapsible="icon"
      onChange={(key) => handleChangeKeys(key)}
      activeKey={openKeys || (opened && [area?.key])}
      expandIcon={({ isActive }) => (
        <Tooltip placement="top" title={`Expand`}>
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
          {/* <ExpandIcon className={`material-icons ${isActive && `expanded`}`}>
          chevron_right
        </ExpandIcon> */}
        </Tooltip>
      )}
      // defaultActiveKey={opened || showOverview ? [area?.key] : []}
    >
      <Panel
        key={area?.key}
        header={
          <PanelHeader
            url={`?v=${area.key}`}
            label={area?.label}
            icon={area?.icon}
            count={indicatorsCount}
            toggleCharts={toggleCharts}
            sortDesc={sortDesc}
            setSortDesc={() => setSortDesc(!sortDesc)}
            expandAll={expandAll}
            collapseAll={collapseAll}
            onHeaderClick={onHeaderClick}
            onTitleClick={() => onTitleClick(area.key)}
            showOverview={showOverview}
          />
        }
      >
        {showOverview ? (
          <GoalTableOverview
            goal={goal}
            fulfillResponse={localFulfillResponse}
            placeDcids={filterParams?.location || [EARTH_PLACE_DCID]}
            infographics={infographics}
          />
        ) : (
          <NestedTable
            columns={columns}
            rowKey={"key"}
            dataSource={filtredData}
            dataStories={dataStories}
            expandedRowKeys={expandedRowKeys}
            onExpand={onExpand}
            localFulfillResponse={localFulfillResponse}
            placeDcids={placeDcids}
            varToTopics={varToTopics}
            setCarouselProps={setCarouselProps}
            goalName={area.label}
            handleChangeKeys={handleChangeKeys}
          />
        )}
      </Panel>
    </AreaCollapse>
  );
};

// const infographics = [
//   {
//     label:
//       "Proportion of population covered by social protection floors/systems, by sex, distinguishing children, unemployed persons, older persons, persons with disabilities, pregnant women, newborns, work-injury victims and the poor and the vulnerable",
//     key: "info_1.1.1",
//     type: "info",
//     to: `?v=${ROOT_TOPIC}#infographic`,
//   },
// ];

const hasIntersection = (arr1, arr2) => {
  const set1 = new Set(arr1);
  return arr2?.some((item) => set1.has(item));
};

const NestedTable = ({
  columns,
  showCarousel,
  dataStories,
  goalName,
  rowKey,
  dataSource,
  expandedRowKeys,
  onExpand,
  localFulfillResponse,
  placeDcids,
  varToTopics,
  setCarouselProps,
  handleChangeKeys,
}) => {
  const { openKeys, setBackUrl } = useContext(OpenKeysContext);
  const location = useLocation();
  const history = useHistory();
  const handleClickURL = (e, url) => {
    const URL = location.pathname + location.search;
    setBackUrl(URL);
    history.push(location.pathname + url);
    e.preventDefault();
  };
  return (
    <>
      {!!dataSource.length &&
        dataSource.map((item) => {
          const [goal, target, indicator] = item?.key ? getGoalTargetIndicator(item?.key.includes("_") ? item.key : "infoinfographic_" + item.key) : [];
          let index = (indicator != "none" && indicator) || (target != "none" && target) || (goal != "none" && goal);

          return (
            <GoalItem
              expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
              items={dataSource}
              onChange={(key) => handleChangeKeys(key)}
              activeKey={openKeys}
            >
              <Panel
                key={item.key}
                header={
                  <TargetName to={`?v=${item.key}`} onClick={(e) => handleClickURL(e, `?v=${item.key}`)}>
                    {index && (
                      <Tooltip title={[`Target`, `Indicator`][index?.split(".")?.length - 2 || 0]}>
                        <GoalBadge color={sdgColors["SDG" + goal]} count={index} />
                      </Tooltip>
                    )}
                    <span>{item.label?.replace(index + ":", "")}</span>
                  </TargetName>
                }
              >
                {!!item.children?.length && !item.children?.[0].type && (
                  <NestedTable
                    columns={columns}
                    rowKey={item.key}
                    dataSource={item.children}
                    dataStories={dataStories}
                    expandedRowKeys={expandedRowKeys}
                    onExpand={onExpand}
                    localFulfillResponse={localFulfillResponse}
                    placeDcids={placeDcids}
                    varToTopics={varToTopics}
                    showCarousel={goal && target && indicator}
                    setCarouselProps={setCarouselProps}
                    goalName={goalName}
                    handleChangeKeys={handleChangeKeys}
                  />
                )}
                {showCarousel && !!item.children?.length && (
                  <div>
                    {item.children.map((a, i) => {
                      let codes = varToTopics[item.key];
                      const key = Object.values(a.key)?.[0];
                      return (
                        <IndicatorLink
                          color={sdgColors["SDG" + goal]}
                          // to={`?v=${item.key}#catalogue`+key}
                          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                        >
                          <Panel
                            key={item.key + i}
                            header={
                              <TargetName onClick={(e) => handleClickURL(e, `?v=${item.key}#${a.tileId || a.key}`)} to={`?v=${item.key}#${a.tileId || a.key}`}>
                                <Tooltip placement="left" title={"Desegregated Series"}>
                                  <Bullet color={sdgColors["SDG" + goal]} />
                                </Tooltip>
                                <span>
                                  {a.title}
                                  {/* {a.key[0]} */}
                                  {/* <IndicatorName item={{...a,key}}/> */}
                                  {a.type == "infographic" && (
                                    <CustomBadge>
                                      {/* <GoalBadge
                                      style={{ marginLeft: "6px" }}
                                      color={sdgColors["SDG" + goal]}
                                      count={`Infographic`}
                                    /> */}
                                      <Tooltip placement="right" title="Infographic">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                          <path
                                            d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM19 19H5V5H19V19Z"
                                            fill="#323232"
                                          />
                                          <path d="M9 12H7V17H9V12Z" fill="#323232" />
                                          <path d="M17 7H15V17H17V7Z" fill="#323232" />
                                          <path d="M13 14H11V17H13V14Z" fill="#323232" />
                                          <path d="M13 10H11V12H13V10Z" fill="#323232" />
                                        </svg>
                                      </Tooltip>
                                    </CustomBadge>
                                  )}
                                  {a.type == "dataStory" && (
                                    <CustomBadge>
                                      {/* <GoalBadge
                                      style={{ marginLeft: "6px" }}
                                      color={sdgColors["SDG" + goal]}
                                      count={`Data Story`}
                                    /> */}
                                      <Tooltip placement="right" title="Data Story">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                          <path
                                            d="M19 5V19H5V5H19ZM19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3Z"
                                            fill="#323232"
                                          />
                                          <path d="M14 17H7V15H14V17ZM17 13H7V11H17V13ZM17 9H7V7H17V9Z" fill="#323232" />
                                        </svg>
                                      </Tooltip>
                                    </CustomBadge>
                                  )}
                                </span>
                              </TargetName>
                            }
                          >
                            <ChartCarousel topics={[item.key] || []} placeDcid="Earth" name={item.label} codes={a.key} limit={200} />
                          </Panel>
                        </IndicatorLink>
                      );
                    })}
                  </div>
                )}
              </Panel>
            </GoalItem>
          );
        })}
    </>
  );
};
export default GoalTable;

interface EntityCount {
  count: number;
  facet: string;
}

interface ObservationDate {
  date: string;
  entityCount: EntityCount[];
}

interface DatesByVariable {
  observationDates: ObservationDate[];
  variable: string;
}

interface Data {
  datesByVariable: DatesByVariable[];
  facets: Record<string, any>;
}

const getHighestDateWithMostEntityCount = (data: Data): { date: string; count: number } | null => {
  let highestDate: string | null = null;
  let highestCount = 0;

  data.datesByVariable.forEach((variable) => {
    variable.observationDates.forEach((observation) => {
      const totalEntityCount = observation.entityCount.reduce((sum, entity) => sum + entity.count, 0);

      if (totalEntityCount > highestCount || (totalEntityCount === highestCount && (!highestDate || observation.date > highestDate))) {
        highestCount = totalEntityCount;
        highestDate = observation.date;
      }
    });
  });

  return highestDate ? { date: highestDate, count: highestCount } : null;
};

export const IndicatorName = ({ item }) => {
  const [title, setTitle] = useState(null);

  useEffect(() => {
    if (!item?.title?.includes("${date}")) {
      setTitle(item.title);
      return false;
    }
    getDates(item.key).then((date) => {
      let camp = getHighestDateWithMostEntityCount(date);
      setTitle(item.title.replace("${date}", camp.date));
    });
  }, []);

  return title ? <span>{title}</span> : <Spinner />;
};

async function getDates<T>(code): Promise<T> {
  let obj = {
    parentEntity: "Earth",
    childType: "Country",
    variable: code,
  };
  const queryString = new URLSearchParams(obj).toString();
  const response = await fetch(`${WEB_API_ENDPOINT}/api/observation-dates?${queryString}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    // body: JSON.stringify(),
  });
  return await (response.json() as Promise<T>);
}

export const PanelHeader: React.FC<{
  url: string | undefined;
  label: string | undefined;
  icon: string | undefined;
  count: number | undefined;
  toggleCharts: boolean;
  sortDesc?: boolean;
  setSortDesc?: () => void;
  expandAll?: () => void;
  collapseAll?: () => void;
  onHeaderClick?: () => void;
  onTitleClick?: () => void;
  showOverview?: boolean;
}> = ({ url, label, icon, count, onHeaderClick, showOverview, onTitleClick }) => {
  const { setShowOverview } = useContext(OpenKeysContext);
  const handleClick = (e) => {
    if (onTitleClick) {
      onTitleClick();
    }
    e.preventDefault();
  };

  return (
    <GridHeader>
      <Row gutter={8}>
        <div>
          <span>
            {icon && (
              <Col>
                <Tooltip title={`Goal`}>
                  <img src={`${icon}`} />
                </Tooltip>
              </Col>
            )}
            <Col span={19}>
              <Link onClick={handleClick} to={url}>
                <Tooltip placement="top" title={`Goal ${label}`}>
                  <strong>{label}</strong>
                </Tooltip>
              </Link>
            </Col>
          </span>
          <Col>
            <span onClick={onHeaderClick} to={url}>
              <ShowOverviewToggler showOverview={!!showOverview} />
            </span>
          </Col>
        </div>
        <div className="filters">
          <FiltersCol>
            <span onClick={() => showOverview && setShowOverview(false)}>
              Found&nbsp;<strong>{count}</strong>&nbsp;item(s)
            </span>
          </FiltersCol>
          {/* {!showOverview &&
          <Col>
            {!toggleCharts ? (
              <Tooltip placement="top" title="List view">
                <AppstoreOutlined onClick={expandAll} />
              </Tooltip>
            ) : (
              <Tooltip placement="top" title="Chart view">
                <UnorderedListOutlined onClick={collapseAll} />
              </Tooltip>
            )}
          </Col>
          }
          <Col>
            {sortDesc ? (
              <Tooltip placement="top" title="Descending order">
                <UpOutlined onClick={setSortDesc} />
              </Tooltip>
            ) : (
              <Tooltip placement="top" title="Ascending order">
                <DownOutlined onClick={setSortDesc} />
              </Tooltip>
            )}
          </Col> */}
        </div>
      </Row>
    </GridHeader>
  );
};
const FiltersCol = styled(Col)`
  display: inline-flex;
  span {
    font-size: 12px;
    cursor: pointer;
  }
`;
export const ShowOverviewToggler: React.FC<{ showOverview: boolean }> = ({ showOverview }) => {
  return (
    <>
      {showOverview ? (
        <Tooltip placement="right" title="Show Catalogue">
          <WrapperBtn>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M19 5V19H5V5H19ZM19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3Z" fill="#7C7C7C" />
              <path d="M14 17H7V15H14V17ZM17 13H7V11H17V13ZM17 9H7V7H17V9Z" fill="#7C7C7C" />
            </svg>
          </WrapperBtn>
        </Tooltip>
      ) : (
        <Tooltip placement="right" title="Show Overview">
          <WrapperBtn>
            <span className="material-icons">list</span>
          </WrapperBtn>
        </Tooltip>
      )}
    </>
  );
};

const WrapperBtn = styled.a`
color: #7C7C7C;
  > svg {
    width: 24px !important;
  }
  > span {
    color: #7C7C7C;
    svg {
      width: 24px !important;
    }
  }
`;
const Bullet = styled.span`
  background: ${({ color }) => color || "#fff"};
  width: 6px;
  min-width: 6px;
  height: 6px;
  border-radius: 8px;
  margin: 7px 7px 0 22px;
`;
const GoalBadge = styled(Badge)`
  margin: 0 8px 0 0px;
  .ant-scroll-number {
    border-radius: 4px;
    border: 1px solid ${({ color }) => color || "#1890ff"};
    background: ${({ color }) => color || "#fff"};
  }
`;
export const ExpandIcon = styled.span`
  font-size: 18px !important;
  margin-right: 0 !important;
  transition: transform 0.3s ease;
  cursor: pointer;
  &.expanded {
    transform: rotate(90deg);
  }
`;
const IndicatorLink = styled(Link)`
  .ant-scroll-number {
    background: transparent !important;
    color: ${({ color }) => color || "#000"};
  }
`;
const GoalItem = styled(Collapse)`
  border: none;
  background-color: transparent;
  > .ant-collapse-item {
    border: none;
  }
  .ant-collapse-content {
    border: none;
  }
  .ant-collapse-header {
    padding: 3px !important;
    .ant-collapse-header-text {
      padding-left: 24px;
    }
    .ant-row {
      padding-left: 28px !important;
      margin: 0 0 1rem !important;
      transition: all 0.3s;
      border-bottom: none !important;
    }
    &[aria-expanded="false"] {
      .ant-row {
        border-color: #fff;
        margin: 0 0 !important;
      }
      .ant-collapse-header-text .ant-scroll-number {
      }
    }
    &[aria-expanded="true"] > .ant-collapse-header-text .ant-scroll-number {
    }
    img {
      height: 24px;
    }
  }
  .ant-collapse-expand-icon {
    padding-left: 6px;
    position: absolute;
    height: 28px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
  }
`;
const AreaCollapse = styled(Collapse)`
  border-left: none;
  border-right: none;
  > .ant-collapse-item {
    border: none;
  }
  .ant-collapse-header {
    padding: 3px !important;
    .ant-row {
      padding-left: 28px;
      margin: 0 0 !important;
      transition: all 0.3s;
      border-bottom: none;
    }
    &[aria-expanded="false"] .ant-row {
      border-color: #fff;
      margin: 0 0 !important;
    }
    img {
      -khtml-user-select: none;
      -o-user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      user-select: none;
      height: 24px;
    }
  }
  .ant-collapse-expand-icon {
    padding-left: 6px;
    position: absolute;
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
  }
`;
const TargetName = styled(Link)`
  padding: 4px 0;
  display: inline-flex;
`;

const CustomBadge = styled.span`
  margin-left: 4px;
  svg {
    width: 20px;
    height: 20px;
  }
`;
