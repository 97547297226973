import { Layout } from "antd";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import useFetchArticles, { GroupedArticles } from "../../../../hooks/useArticles";
import { useStoreState } from "../../state";
import { EARTH_PLACE_DCID, ROOT_TOPIC } from "../../utils/constants";
import { theme } from "../../utils/theme";
import { FulfillResponse } from "../../utils/types";
import { buildTileHierarchy, Spinner } from "../countries/CountriesContent";
import { ContentCard } from "../shared/components";
import GoalOverview from "../shared/goals/GoalOverview";
import { keyBy } from "./DataStories";
import InfographicView from "./InfographicView";

const GoalTableOverview: React.FC<{
    fulfillResponse?: FulfillResponse;
    placeDcids: string[];
    goal: string;
    infographics:[]
  }> = ({ fulfillResponse, placeDcids, goal, infographics }) => {

    const { fetchArticles } = useFetchArticles();
    const [topics, setTopics] = useState<false | GroupedArticles | undefined | null>(null);
    const [childTopics, setChildTopics] = useState<false | GroupedArticles | undefined | null>({});
    const sidebarMenuHierarchy = useStoreState((s) => s.sidebarMenuHierarchy);
    const mainTopicDcids = fulfillResponse?.relatedThings?.mainTopics?.map((e) => e.dcid) || [];
    const processedTiles = fulfillResponse?.config && buildTileHierarchy(fulfillResponse?.config?.categories[0], mainTopicDcids, fulfillResponse.relatedThings.varToTopics);
    const isMounted = useRef(true);
    
    useEffect(() => {
        isMounted.current = true;
        fetchArticles([`${ROOT_TOPIC}_${goal}`]).then(resp => {
          if (isMounted.current){
            setTopics(resp)
          }
        });
        return () => {
          isMounted.current = false;
        };
      }, [goal, fetchArticles]);

    useEffect(()=>{
      if(fulfillResponse?.relatedThings?.childTopics && sidebarMenuHierarchy[goal]){
        setChildTopics(!sidebarMenuHierarchy[goal].children ? {} : keyBy(sidebarMenuHierarchy[goal].children.filter(a=>!a.key?.includes('summary-')).map(a=>({...a, number: a.key.split('_')[1]})), 'number'))
      }
    },[fulfillResponse])

    const color = theme.sdgColors[Number(goal) - 1];
    const statVarSpec = fulfillResponse?.config.categories && fulfillResponse?.config.categories[0]
    const infographic = infographics?.find(a=>a.key.startsWith(`${goal}.`))
    const isWorld = placeDcids[0] == EARTH_PLACE_DCID
    
    return <OverviewLayout style={{ height: "100%", flexGrow: 1, margin: '0 -16px' }}  id="top">
            <Layout.Content style={{ background: theme.searchBackgroundColor }}>
                {
                    topics && Object.keys(topics).length && topics[`${ROOT_TOPIC}_${goal}`] ?
                    <GoalOverview goalData={topics[`${ROOT_TOPIC}_${goal}`]} showDataStories={isWorld} goalNumber={Number(goal)} showExploreLink={true} />
                    : <ContentCard><Spinner/></ContentCard>
                }
                {isWorld && infographic &&
                <InfographicLayout>
                    {/* <h3>Infographics</h3> */}
                    {infographics?.filter(a=>a.key.startsWith(`${goal}.`)).sort((a,b) => a.key.localeCompare(b.key)).map(item =>
                    <InfographicView key={item.key} item={item} color={color}/>
                  )}
                </InfographicLayout>
                }
            </Layout.Content>
            </OverviewLayout>
  }
export default GoalTableOverview

const InfographicLayout = styled.div`
  margin: 0 0;
  h3 {
    font-weight: normal;
    padding: 0 1rem 1rem;
    font-size: 2rem;
  }
>div {
    background: #fff;
    padding: 1rem 0;
}
`
const OverviewLayout = styled(Layout)`
  .ant-layout-content {
    > div:last-child {
        margin-bottom: 0;
    }
}
.ant-collapse-header .ant-collapse-expand-icon {
    padding-left: 6px;
    position: static;
    height: 21px;
}
`