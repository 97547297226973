import { ArrowLeftOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import { useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { routeDataCommonsConstants } from "../../../../helper/Common/RoutePathConstants.js";
import { useStoreState } from "../../state";
import { BackButton, OpenKeysContext, SubAreacard } from "../areas/ThematicAreaView";
import { IndicatorHeader } from "../shared/goals/IndicatorOverview";
import GeneralCarousel from "./GeneralCarousel";
import Indicator from "./Indicator";

const SubThemeCarousel:React.FC<{
    data: {}
}> = ({data}) => {
    const store = useStoreState((s) => s);
    const history = useHistory();
    const location = useLocation();
    const { backUrl } = useContext(OpenKeysContext);
    const goBack = () => {
        if (!backUrl) goList();
        else history.push(backUrl);
    };
    
    const goList = () => {
        history.push(location.pathname || routeDataCommonsConstants.THEMATIC_AREAS);
    };

    const items = data.indicatorId ? data.children?.filter(a=>a.id==data.indicatorId) : data.children
    const urlParams = [`#explore`, data.id, data.indicatorId].filter(a=>!!a)

    return <CarouselContainer>
        <CarouselHeader>
          <Row>
            {data.label && (
              <Col flex="0 1">
                <BackButton size="small" onClick={goBack}>
                  <ArrowLeftOutlined />
                </BackButton>
              </Col>
            )}
            <Col flex="1 1">
              <span>
                {data.label}
              </span>
            </Col>
          </Row>
        </CarouselHeader>
        <SubAreacard style={{ backgroundColor: "transparent", margin: 0 }}>
        <GeneralCarousel
            // topics={topics} 
            placeDcid="Earth" 
            // codes={codes} 
            limit={200} 
            // initialSlide={index}
            exploreLink={urlParams.join('+')}
        >
            {data.indicatorId && items?.[0]?.indicator_codes.map(code => <SubAreacard key={code}>
                <Indicator
                placeDcid="Earth"
                store={store}
                name={items[0].indicator_name}
                code={code}
                limit={1}
                />
            </SubAreacard>)}

            {!data.indicatorId && items && items.map(record => <SubAreacard key={record.id}>
                <Indicator
                placeDcid="Earth"
                store={store}
                name={record.indicator_name}
                code={record.indicator_codes[0]}
                limit={1}
                />
            </SubAreacard>
            )}
        </GeneralCarousel>
        </SubAreacard>
    </CarouselContainer>
}
export default SubThemeCarousel

const CarouselHeader = styled(IndicatorHeader)`
    margin: 0;
    font-weight: bold;
    padding: 8px 16px;
    border-bottom: solid #939598 1px;
    > .ant-row {
        .ant-col .ant-btn {
            display: flex; 
            align-items: center;
        }
    }
`
const CarouselContainer = styled.div`
    .index-text {
        top: -32px;
    }
    .ant-carousel {
        .slick-arrow.slick-next,
        .slick-arrow.slick-prev {
            top: -38px!important;
            background: transparent;
        }
        .slick-list {
            min-height: 250px;
        }
    }
`