import { CaretDownOutlined } from "@ant-design/icons";
import { Card, Col, Divider, Layout, Row, Select, Typography } from "antd";
import Title from "antd/lib/typography/Title.js";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { routeDataCommonsConstants } from "../../../../helper/Common/RoutePathConstants.js";
import { useStoreState } from "../../state/index";
import { Spinner } from "../countries/CountriesContent";
import AppBreadcrumb from "../shared/AppBreadcrumb";
import AppLayoutContent from "../shared/AppLayoutContent";
import { ExploreSection } from "./ExploreSection";
import { base64Encode, serializeAreaParams } from "./ThematicAreaView";

const { Option } = Select;
const { Text } = Typography;

const ThematicAreas: React.FC = () => {
  const [topics, setTopics] = useState([]);
  const [loading, setLoading] = useState<boolean>(true);

  const history = useHistory();
  const countries = useStoreState((s) => s.countries.dcids.map((dcid) => s.countries.byDcid[dcid]));
  const regions = useStoreState((s) => s.regions.dcids.map((dcid) => s.regions.byDcid[dcid]));

  const [images, setImages] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isFading, setIsFading] = useState(false);

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/config/sidebar_merged.json`)
      .then((response) => response.json())
      .then((data) => {
        setTopics(data.sort((a, b) => a.label.localeCompare(b.label)));
        setLoading(false);
      })
      .catch((error) => console.error("Error loading sidebar_merged.json:", error));
  }, []);

  const opt = [countries, regions].flat().map((place) => ({ value: place.dcid, title: place.name, id: place.dcid }));
  opt.sort(({ value: value1 }, { value: value2 }) => value1.localeCompare(value2));

  const treeData = [
    {
      title: "World",
      value: "Earth",
      key: "0",
    },
    ...opt,
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setIsFading(true);
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        setIsFading(false);
      }, 500); // Duration of fade-out
    }, 15000);
    return () => clearInterval(interval);
  }, [images]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.PUBLIC_URL}/config/thematic_area_banners.json`);
        const json = await response.json();
        setImages(json.filter((i) => i.is_active == 1));
      } catch (error) {
        console.error("Error fetching JSON data:", error);
      }
    };

    fetchData();
  }, []);

  const allThematic64 = "RWFydGgmJmFsbA==";
  const handleChange = (value: { value: string }) => {
    const str = serializeAreaParams({
      location: [value],
      partners: ["all"],
      thematics: ["0"],
    });
    history.push(`${routeDataCommonsConstants.THEMATIC_AREAS}/${base64Encode(str)}`);
  };

  return (
    <>
      <AppLayoutContent style={{ backgroundColor: "#F1F1F1" }}>
        <CountrySelectorBlock isFading={isFading}>
          <Layout style={{ padding: "0rem 1rem", backgroundColor: "#F1F1F1" }} className="container">
            <AppBreadcrumb>Thematic Areas</AppBreadcrumb>
          </Layout>

          {images.length && (
            <div className="selector-wrapper">
              <div
                className="background-layer"
                style={{
                  backgroundImage: `url(${images[currentIndex]?.image || ""})`,
                }}
              ></div>
              <div className="content-layer">
                <h3 className="title">Explore Thematic Areas Data Catalogue</h3>
                <CountrySelectContainer>
                  <Select
                    suffixIcon={<CaretDownOutlined style={{ color: "#000055" }} />}
                    optionLabelProp="label"
                    onChange={handleChange}
                    placeholder="Select a country or area"
                  >
                    {treeData.map((item) => (
                      <Option key={item.id || item.key} value={item.id || item.key} label={item.title}>
                        {item.title}
                      </Option>
                    ))}
                  </Select>
                </CountrySelectContainer>
                <p>Learn about Thematic Areas by location and Data Partners through the UN Data Commons</p>
              </div>
              <span className="photo-credits">© {images[currentIndex]?.credits}</span>
            </div>
          )}
        </CountrySelectorBlock>
        <Layout style={{ padding: "0rem 1rem", backgroundColor: "#F1F1F1" }} className="container">
          <ListHeader>
            <Title level={3}>Explore Thematic Areas by Theme</Title>
            <Divider />
            <Text>
              Learn Gain valuable insights into the challenges and progress shaping our interconnected world, with datasets and visualizations available across
              12 thematic areas.
            </Text>
          </ListHeader>
          <AreaBody>
            {loading && <Spinner />}
            {!loading && topics && (
              <>
                <ThematicRowContainer>
                  <ThematicRow gutter={[16, 16]} justify="center">
                    {topics
                      // .filter((v) => v.type != "placeholder")
                      .map((item) => (
                        <Col xs={24} md={12} key={item.id}>
                          <Link to={`${routeDataCommonsConstants.THEMATIC_AREAS}/${item.type == "placeholder" ? allThematic64 : item.id}`}>
                            <ThematicCard>
                              <span>
                                {item.type == "placeholder" ? (
                                  <img width={54} src={"./images/datacommons/ta-theme.svg?v=2.0"} />
                                ) : (
                                  <img width={54} src={item.icon} />
                                )}
                              </span>
                              <h3>{item.label}</h3>
                            </ThematicCard>
                          </Link>
                        </Col>
                      ))}
                    <Col xs={24} md={12}>
                      <Link to={routeDataCommonsConstants.PARTNER}>
                        <ThematicCard>
                          <span>
                            <img src="./images/datacommons/data-partners.svg" alt="Partners" width={54} />
                          </span>
                          <h3>Thematic Areas Data Partners</h3>
                        </ThematicCard>
                      </Link>
                    </Col>
                  </ThematicRow>
                </ThematicRowContainer>
              </>
            )}
          </AreaBody>
        </Layout>

        <ExploreSection />
      </AppLayoutContent>
    </>
  );
};

export default ThematicAreas;

const ListHeader = styled.div`
  width: 90%;
  max-width: 640px;
  margin: auto;
  padding-top: 4rem;
  padding-bottom: 3rem;
  text-align: center;
  .ant-typography {
    color: #414042;
    font-style: normal;
  }
  .ant-divider {
    border-top: 1px solid #999;
  }
`;

const CountrySelectorBlock = styled.div<{ bgImage: string; isFading: boolean }>`
  opacity: 0.98;
  .selector-wrapper {
    padding-top: 4rem;
    position: relative;

    .background-layer {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      transition: opacity 0.5s ease-in-out;
      opacity: ${(props) => (props.isFading ? 0 : 1)};
      z-index: 0;
    }

    .content-layer {
      position: relative;
      z-index: 1;
      background: #fffffff2;
      margin: 0 auto;
      width: 90%;
      max-width: 735px;
      border-radius: 8px;
      padding: 2rem;
      .ant-select {
        width: 100%;
        margin-bottom: 2rem;
      }
      > p {
        color: #7c7c7c;
        text-align: center;
        font-size: 16px;
      }
    }
    .photo-credits {
      position: relative;
      z-index: 1;
      color: #fff;
      max-width: 968px;
      margin: 0 auto;
      display: block;
      padding-top: 2rem;
      padding-bottom: 4px;
      font-size: 12px;
      @media (max-width: 768px) {
        padding-left: 16px;
      }
    }
    .title {
      color: #449bd5;
      font-size: 29px;
      text-align: center;
      font-style: normal;
      font-weight: 500;
      padding-bottom: 2rem;
    }
  }
`;

const AreaTop = styled.div`
  // background-image: url("./images/datacommons/search-background.png");
  background-image: url("./images/datacommons/search-bg.png");
  background-size: 100% auto;
  padding: 0 2rem 1rem;
  h3 {
    color: inherit;
    font-size: 36px;
    font-weight: 700;
  }
  p {
    padding-top: 0.5rem;
    color: #616161;
    width: 60%;
  }
  @media only screen and (max-width: 800px) {
    padding: 0 0 1rem;
    p {
      width: 100%;
    }
  }
`;

const AreaBody = styled.div`
.ant-select{
  width: 300px;
}
@media only screen and (max-width: 800px) {
.ant-select {
    width: 100%;
  }
}

p {
    color: #616161);
    font-size: 12px;
    padding: 0.5rem 0 1rem 1rem;
}
`;

const ThematicRowContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
`;

const ThematicRow = styled(Row)`
  max-width: 968px;
  margin: 0 auto 3.5rem;
  justify-content: center;
`;

const ThematicCard = styled(Card)`
  border-radius: 8px;
  border: none;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  transition: 0.3s;
  &:hover {
    background: #f2f2f270;
  }
  .ant-card-body {
    display: flex;
    align-items: center;
    justify-content: left;

    img {
      margin-right: 1.6rem;
    }
  }
`;
const AreaCard = styled.div`
  border-radius: 8px;
  border: 1px solid var(--boarder-boarder-primary, #d3d3d3);
  span {
    display: block;
    padding: 2rem;
    background-color: #f1f1f1;
    text-align: center;
  }
  h3 {
    font-size: 22px;
    padding: 20px;
  }
`;

const CountrySelectContainer = styled.div<{ width: string }>`
  display: flex;
  position: relative;
  width: ${(p) => p.width};
  height: 100%;
  .ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-input,
  .react-dropdown-select {
    border-radius: 2rem !important;
    height: 38px;
    font-size: 16px;
  }
  .ant-select-selection-placeholder {
    color: black;
    padding-top: 3px !important;
  }
  svg {
    pointer-events: none;
    position: absolute;
    right: 5px;
    top: 0;
    font-size: 1rem;
  }
`;
