import { Carousel } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { WEB_API_ENDPOINT } from "../../utils/constants";
import DataCommonsClient from "../../utils/DataCommonsClient";
import { Spinner } from "../countries/CountriesContent";
import { ContentCard } from "../shared/components";
import { renderChart } from "./Indicator";

const ChartCarousel: React.FC<{
    codes: string[];
    name: string | undefined;
    isTopic: boolean | undefined;
    limit: number | undefined;
    placeDcid: string | undefined;
    store: object | undefined;
    hideAlternates: boolean | undefined;
    setter: boolean | undefined;
    topics: [] | undefined;
    children: (props: { id: string }) => React.ReactNode;
    initialSlide: number | undefined;
    exploreLink: string | undefined;
  }> = ({ codes, exploreLink, name, isTopic, limit, placeDcid, store, hideAlternates, setter, topics, children, initialSlide }) => {

  const [indicators, setIndicators] = useState(null);
  const [places, setPlaces] = useState([]);
  const fullfilCalledRef = useRef(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const carouselRef = useRef();

  useEffect(()=>{
    if(initialSlide)setCurrentIndex(initialSlide)
  }, [initialSlide])

  useEffect(() => {
    if(!codes?.length) return;
    setIndicators(null);
    fullfilCalledRef.current = false;
    let code = codes[0]
      getInfo(codes.join('&dcids=')).then((res) => {

        if (typeof res[code] != "undefined") {
          const type = getType(res[code]);
          const topPlaces = res[code].placeTypeSummary[type].topPlaces.map((m) => m.dcid);
          fullfil(codes, placeDcid ? [placeDcid] : topPlaces);
        } else {
          setIndicators([]);
        }
      });

  }, [codes, placeDcid]);

  useEffect(()=>{
    if(!codes) setLoading(false)
  },[children])

  // useEffect(() => {
    // Force recalculation after component mounts
    // setTimeout(() => {
    //   carouselRef.current?.innerSlider.onWindowResized();
    // }, 1000);
  // }, [currentIndex]);

  async function getInfo<T>(code: string): Promise<T> {
    const response = await fetch(`${WEB_API_ENDPOINT}/api/variable/info?dcids=${code}`);
    return await (response.json() as Promise<T>);
  }

  const getType = (data) => {
    if (typeof data.placeTypeSummary.GeoRegion != "undefined") {
      return "GeoRegion";
    }

    if (typeof data.placeTypeSummary.Country != "undefined") {
      return "Country";
    }

    return null;
  };

  const fullfil = (code, entities) => {
    if (fullfilCalledRef.current) return;
    fullfilCalledRef.current = true;

    const placeList = entities ? entities : [placeDcid ? placeDcid : "Earth"];
    setPlaces(placeList);
    const client = new DataCommonsClient({ apiRoot: WEB_API_ENDPOINT });
    client
      .fulfill({
        dc: "undata",
        entities: placeList,
        variables: !Array.isArray(code)?[code]:code,
        childEntityType: "",
        comparisonEntities: [],
        comparisonVariables: [],
      })
      .then((v) => {
        let items = getFullfillResponse(v, code)
        if(items.length){
          setIndicators( items.flatMap(a=>a.columns) );
        }
        setLoading(false)
      });
  };

  const getFullfillResponse = (response, code) => {
    
    let results = [];
    if (typeof response.config.categories != "undefined") {
      if (typeof response.config.categories[0].blocks != "undefined") {
        results = prioritizeCharts(response.config.categories[0].blocks, response);
      }
    }
    return results;
  };

  const prioritizeCharts = (blocks, response) => {
    const charts = [];
    const highlights = [];
    blocks.forEach((element) => {
      if (typeof element.columns[0] != "undefined") {
        if (element.columns[0].tiles[0].type != "PLACE_OVERVIEW" && element.columns[0].tiles[0].type != "HIGHLIGHT") {
          element.columns[0].tiles[0].statVarKey = findStatVarKeys(element.columns[0].tiles[0].statVarKey, response.config.categories[0].statVarSpec);
          charts.push(element);
        }
      }
    });

    blocks.forEach((element) => {
      if (typeof element.columns[0] != "undefined") {
        if (element.columns[0].tiles[0].type == "HIGHLIGHT") {
          element.columns[0].tiles[0].statVarKey = findStatVarKeys(element.columns[0].tiles[0].statVarKey, response.config.categories[0].statVarSpec);
          highlights.push(element);
        }
      }
    });
    return [...charts, ...highlights];
  };

  const findStatVarKeys = (statVarKeys, statVarSpec) => {
    const results = [];
    statVarKeys.forEach((key) => {
      if (statVarSpec.hasOwnProperty(key)) {
        results.push(statVarSpec[key].statVar);
      }
    });
    return results;
  };

  const handleBeforeChange = (from, to) => {
    setCurrentIndex(to);
  };

  const handleAfterChange = () => {
    carouselRef.current.innerSlider.onWindowResized();
  }
  const childCount = children ? React.Children.count(children.filter(a=>!!a)) : 0;

return <ChartCarouselContainer>
      {!!childCount && 
        <div className="index-text">
          {currentIndex+1} of {childCount} Item(s)
          {exploreLink && <Link to={exploreLink}>
          <svg style={{marginLeft:'8px'}} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g clip-path="url(#clip0_35250_81252)">
              <rect width="20" height="20" transform="translate(0.0556641 0.103027)" fill="white"/>
              <path d="M17.5555 15.1029H1.72217V16.7696H17.5555V15.1029ZM15.8888 8.43628V11.7696H3.38883V8.43628H15.8888ZM16.7222 6.76961H2.5555C2.09717 6.76961 1.72217 7.14461 1.72217 7.60295V12.6029C1.72217 13.0613 2.09717 13.4363 2.5555 13.4363H16.7222C17.1805 13.4363 17.5555 13.0613 17.5555 12.6029V7.60295C17.5555 7.14461 17.1805 6.76961 16.7222 6.76961ZM17.5555 3.43628H1.72217V5.10295H17.5555V3.43628Z" fill="#323232"/>
            </g>
            <defs>
              <clipPath id="clip0_35250_81252">
                <rect width="20" height="20" fill="white" transform="translate(0.0556641 0.103027)"/>
              </clipPath>
            </defs>
          </svg>
          </Link>
          }
        </div>
      }
      {loading ? <ContentCard><Spinner/></ContentCard>
      : <Carousel 
        lazyLoad="progressive"
        ref={carouselRef}
        beforeChange={handleBeforeChange} 
        nextArrow={<CustomPrevArrow/>} 
        prevArrow={<CustomNextArrow/>} 
        arrows
        dots={false}
        initialSlide={initialSlide||0}
        adaptiveHeight
      >
        {children}
      {
        indicators?.length && indicators.map((item, k) => {
          if(item.tiles[0])
          return <div key={k}>
            {renderChart(item.tiles[0].type, {columns:[item]}, item.tiles[0], topics, places)}
          </div>
        })
      }
      </Carousel>}
    </ChartCarouselContainer>
}



export default ChartCarousel
export const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style}}
      onClick={onClick}
    >
       <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M8.0876 5L6.9126 6.175L10.7293 10L6.9126 13.825L8.0876 15L13.0876 10L8.0876 5Z" fill="black"/>
      </svg>
    </div>
  );
};

export const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style }}
      onClick={onClick}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M13.0876 6.175L11.9126 5L6.9126 10L11.9126 15L13.0876 13.825L9.27093 10L13.0876 6.175Z" fill="black"/>
      </svg>
    </div>
  );
};
export const ChartCarouselContainer = styled.div`
  background: #fff;
  border-radius:8px;
  margin: -6px 0;
  // position: relative;
  .ant-btn {
    &[disabled] {
        opacity: 40%;
      svg {
      }
    }
    svg {
      width:24px!important;
    }
  }
  .ant-carousel 
  {
    .slick-slider {
      position: static;
    }
    .slick-arrow.slick-prev,
    .slick-arrow.slick-next {
      background:#fff;
      top: 20px!important;
    }
  }
  .index-text {
    background: #fff;
    padding: 0 5px;
    font-size: 13px;
    line-height: 26px;
    position: absolute;
    top: 26px;
    z-index: 5;
    right: 50px;
  }

  datacommons-map::part(source-separator) {
    display: none;
  }
  datacommons-map::part(source-show-metadata-link) {
    display: none;
  }

  datacommons-line::part(source-separator) {
    display: none;
  }
  datacommons-line::part(source-show-metadata-link) {
    display: none;
  }

  datacommons-bar::part(source-separator) {
    display: none;
  }
  datacommons-bar::part(source-show-metadata-link) {
    display: none;
  }

  datacommons-highlight::part(source-separator) {
    display: none;
  }
  datacommons-highlight::part(source-show-metadata-link) {
    display: none;
  }
`