import { Button, Col, Dropdown, Layout, Menu, Row, TreeDataNode } from "antd";
import Search from "antd/lib/input/Search.js";
import React, { createContext, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import { routeDataCommonsConstants } from "../../../../helper/Common/RoutePathConstants.js";
import { useStoreState } from "../../state";
import { EARTH_PLACE_DCID } from "../../utils/constants";
import { Spinner } from "../countries/CountriesContent";
import AppBreadcrumb from "../shared/AppBreadcrumb";
import AppLayoutContent from "../shared/AppLayoutContent";
import { ContentCard, Footnotes, IndicatorHeader, MultipleSelect, SearchWrapper } from "../shared/components";
import ExploreSubTheme from "./ExploreSubTheme";
import SubThemeCarousel from "./SubThemeCarousel";
import ThematicAreaWrapper from "./ThematicAreaWrapper";
                                                                                                                                                                                                                                                                                                                   
export interface FilterParams {
  location: string[];
  partners?: string[];
  thematics?: number[] | string[];
  que: string;
  statVars?: string[];
  topics?: number[] | string[];
}

export const OpenKeysContext = createContext([]);

const OpenKeysProvider = ({ children }) => {
  const [openKeys, setOpenKeys] = useState([]);
  const [showOverview, setShowOverview] = useState<boolean>(true);
  const [backUrl, setBackUrl] = useState<string | null>(null);
  return <OpenKeysContext.Provider value={{ openKeys, setOpenKeys, showOverview, setShowOverview, backUrl, setBackUrl }}>{children}</OpenKeysContext.Provider>;
};

const ThematicAreaView: React.FC = () => {
  const params = useParams();
  const history = useHistory();
  const { hash } = useLocation();
  const [indicator, setIndicator] = useState("");
  const [theme, setTheme] = useState("");
  const [subtheme, setSubTheme] = useState("");
  const [url, setURL] = useState(routeDataCommonsConstants.THEMATIC_AREAS);
  const store = useStoreState((s) => s);
  const [topics, setTopics] = useState([]);
  const [contentPage, setContentPage] = useState<{page:string, data:{}|null}>({page:'default', data:null})
  const [thematicAreas, setThematicAreas] = useState([]);
  const [themes, setThemes] = useState([]);
  const [partnerList, setPartnerList] = useState([] as TreeDataNode[]);
  const [loading, setLoading] = useState<boolean>(true);
  const [filterPosition, setFilterPosition] = useState<string>("top");
  const [filterParams, setFilterParams] = useState<FilterParams>({
    que: "",
    location: ["Earth"],
    partners: ["all"],
    thematics: ["0"],
    statVars: [],
  });

  const countries = useStoreState((s) => s.countries.dcids.map((dcid) => s.countries.byDcid[dcid]));
  const regions = useStoreState((s) => s.regions.dcids.map((dcid) => s.regions.byDcid[dcid]));
  const opt = [countries, regions].flat().map((place) => ({ value: place.dcid, title: place.name, id: place.dcid }));
  opt.sort(({ value: value1 }, { value: value2 }) => value1.localeCompare(value2));

  const treeData = [
    {
      title: "World",
      value: "Earth",
      key: "0",
    },
    ...opt,
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.PUBLIC_URL}/config/partners.json`);
        const json = await response.json();
        setPartnerList([
          {
            title: "All Partners",
            value: "all",
            children: json
              .filter((a) => a.hidden != true && a.hideIndicators != true)
              .sort((a, b) => a.label.localeCompare(b.label))
              .map((a) => {
                return { title: a.label, value: a.value, key: a.value };
              }),
          },
        ]);
      } catch (error) {
        console.error("Error fetching JSON data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/config/sidebar_merged.json`)
      .then((response) => response.json())
      .then((data) => {
        let sorted = data.sort((a, b) => a.label.localeCompare(b.label));
        setTopics(sorted);

        // Hierarcical thematic areas
        setThematicAreas(
          sorted.map((a) => {
            return {
              title: a.label,
              value: a.id + "",
              children: a.children
                ?.map((c) => ({ title: c.label, value: a.id + "#" + c.id, children: [] }))
                .filter((a) => {
                  return !a.value?.includes("-topic");
                }),
            };
          })
        );
        setLoading(false);
      })
      .catch((error) => console.error("Error loading sidebar_merged.json:", error));
  }, []);

  useEffect(() => {
    const hideExtraNoDataDivs = () => {
      const divs = document.querySelectorAll("span.no-data-class");
      divs.forEach((div, index) => {
        if (index > 0) {
          div.style.display = "none";
        } else {
          div.style.display = "block";
        }
      });
    };

    // Initial call to hide extra divs
    hideExtraNoDataDivs();

    const observer = new MutationObserver((mutationsList) => {
      for (let mutation of mutationsList) {
        if (mutation.type === "childList" && mutation.addedNodes.length > 0) {
          hideExtraNoDataDivs();
        }
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });
    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (topics.length > 0) {
      const path = window.location.pathname.split("/");
      let slug: string;
      let params: FilterParams = { ...filterParams };
      try {
        slug = base64Decode(path.slice(-1)[0]);

        if (slug.split("&").length > 1)
          params = {
            location: slug.split("&")[0] ? slug.split("&")[0].split(",") : ["Earth"],
            thematics: slug.split("&")[1] ? slug.split("&")[1].split(",") : ["0"],
            partners: slug.split("&")[2] ? slug.split("&")[2].split(",") : ["all"],
            que: slug.split("&")[3] || "",
          };
        else {
          slug = path.slice(-1)[0];
          params.thematics = slug.split(",");
        }
      } catch {
        slug = path.slice(-1)[0];
        params.thematics = slug.split(",")[0] != "undefined" ? slug.split(",") : [0];
      }

      if (params.thematics?.length) {
        if (params.thematics.includes("0") && params.thematics.length > 1) 
          params.thematics = params.thematics.filter((t) => t != 0);

        let areas = params.thematics?.map((code) => {
            let path = (code + "").split("#");
            let topic = topics.find((t) => t.id == path[0]);
            let subTopics = path[1] && topic.children.filter((t) => t.id == path[1]);
            return path[1] ? { ...topic, children: subTopics } : topics.find((t) => t.id == path[0]);
          })
          areas = mergedData(areas)
        setThemes(areas);
        setTheme(params.thematics.map((code) => findItem(topics, code)));
      }
      setFilterParams({ ...filterParams, ...params });

      
      document.getElementById(`top`).scrollIntoView({ behavior: "smooth" });
    }
  }, [topics, window.location.pathname, window.location.search]);

  useEffect(() => {
    if (hash) {
      const path = hash.replace(/#/g, "").split("+");

      if(path[0] && path[1]){
        let subItem = findItem(topics, path[1])
        let parentItem = topics.find(a=>a.children?.find(b=>b.id==path[1]))
        setContentPage({
          page:path[0] == 'explore' ? 'explore' : 'carousel', 
          data: {...subItem, parent: parentItem, color: parentItem?.color, indicatorId: path[2]||null} }
        )
      }

      // if (path[1]) {
      //   if (path[2])
      //     setIndicator(findItem(topics, path[2]));
      //   let subItem = findItem(topics, path[1])
      //   let parentItem = topics.find(a=>a.children?.find(b=>b.id==path[1]))
      //   setSubTheme({...subItem, color: parentItem?.color});
      // }
      //  else if (path[1]) {
      //   setIndicator(null);
      //   setSubTheme(findItem(topics, path[1]));
      // }
    } else {
      setContentPage({
        page: 'default', 
        data: {} }
      )
      
      // setIndicator(null);
      // setSubTheme(null);
    }
    document.getElementById(`top`).scrollIntoView({ behavior: "smooth" });
  }, [window.location.hash, topics]);

  const mergedData = (data) => Object.values(
    data.reduce((acc, obj) => {
      // Check if the `id` exists in the accumulator
      if (!acc[obj.id]) {
        acc[obj.id] = { ...obj }; // Add the object if not present
      } else {
        // Merge children arrays if the ID already exists
        acc[obj.id].children = [...acc[obj.id].children, ...obj.children];
      }
      return acc;
    }, {})
  );

  const area = topics.find((e) => e.id == params.dcid);

  const findItem = (array, code) => {
    let result;
    array.some((child) => {
      return ((Array.isArray(code) ? code.includes(child.id + "") : child.id == code) && (result = child)) || (result = findItem(child.children || [], code));
    });
    return result;
  };

  const flatIndicators = (array, results = [], parents = [], parent = null, topicId = null) => {
    const uniqueIndicators = new Set(results.map((indicator) => indicator.indicator_name));

    array.forEach((item) => {
      let parentChain = [...parents, parent];
      if (item.indicator_name && (!topicId || parentChain.includes(topicId) || topicId === "all")) {
        if (!uniqueIndicators.has(item.indicator_name)) {
          results.push(item);
          uniqueIndicators.add(item.indicator_name);
        }
      }
      if (item.children) {
        flatIndicators(item.children, results, parentChain, item.id, topicId);
      }
    });

    return results;
  };

  const handleSearch = (value) => {
    // Push the search event and term to the data layer for GTM
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "search",
      search_term: value,
      search_section: "thematic_areas",
    });


    let params = { ...filterParams, que: value };
    setFilterParams(params);
    let str = serializeAreaParams(params);
    let str64 = base64Encode(str);
    history.push(routeDataCommonsConstants.THEMATIC_AREAS + "/" + str64);
  };

  const FilterOptions = ({ position }) => {
    const handleFilter = (inputValue, treeNode) => {
      let title = treeNode.title || treeNode.label;
      const matchByIndex = treeNode.key?.toString().includes(inputValue);
      const matchByTitle = title?.toLowerCase().includes(inputValue.toLowerCase());
      return matchByIndex || matchByTitle;
    };

    return (
      <FilterWrapper>
        {position == "left" && (
          <div className="sidebar-title">
            Filter content
            <FilterBtn setFilterPosition={setFilterPosition} />
          </div>
        )}
        <Row className={"position-" + position} gutter={[12, 12]}>
          {position == "top" && (
            <Col span={1}>
              <FilterBtn setFilterPosition={setFilterPosition} />
            </Col>
          )}
          <Col xs={24} md={6}>
            <MultipleSelect
              value={filterParams.location}
              placeholder={`Location | World`}
              filterTreeNode={handleFilter}
              onChange={(location) => onLocationChange({ location })}
              treeData={treeData}
              exeptionValue={EARTH_PLACE_DCID}
              isSingle={true}
            />
          </Col>
          <Col xs={24} md={9}>
            <MultipleSelect
              value={filterParams.thematics}
              placeholder={`Thematic Areas | All`}
              filterTreeNode={handleFilter}
              onChange={(thematics) => onThematicChange({ thematics })}
              treeData={thematicAreas}
              exeptionValue={"0"}
              isSingle={false}
              treeDefaultExpandAll
            />
          </Col>
          <Col xs={24} md={6}>
            <MultipleSelect
              value={filterParams.partners}
              placeholder={`Partners | All`}
              filterTreeNode={handleFilter}
              onChange={(partners) => onPartnersChange({ partners })}
              treeData={partnerList}
              exeptionValue={"all"}
              isSingle={false}
              treeDefaultExpandAll
            />
          </Col>
        </Row>
      </FilterWrapper>
    );
  };

  const ThematicSearch = () => {

    return <SearchWrapper>
      <Row gutter={[12, 12]}>
        <Col className="search-col" span={filterPosition === "none" ? 23 : 24}>
        <Search 
          allowClear 
          key={filterParams.que} 
          defaultValue={filterParams.que} 
          onSearch={handleSearch} 
          // onChange={handlaQueChange}
          placeholder="Search Thematic Areas Catalogue" 
        />
        </Col>
        {filterPosition === "none" && (
          <Col span={1}>
            <FilterBtn setFilterPosition={setFilterPosition}/>
          </Col>
        )}
      </Row>
      {filterPosition == "top" && <FilterOptions position={filterPosition} />}
    </SearchWrapper>
  }

  const thematicSort = (a,b) => {
    return a.key.split('_')[2] - b.key.split('_')[2]
  }

  const getTopics = () => {
    return (
      <>
        {area?.children.length &&
          area?.children.map((v, k) => {
            return (
              <React.Fragment key={k}>

                {v.id == "646330495-topic" && (
                  <SubAreacard>
                    <h3 className="block-header">{v.label}</h3>
                    <p style={{ padding: "1rem" }}>No data found.</p>
                  </SubAreacard>
                )}
              </React.Fragment>
            );
          })
        }

        <GridTableWrapper style={{ backgroundColor: "#fff" }}>
          {loading && <Spinner />}
          {!loading &&
            themes?.length > 0 &&
            (themes.filter((i) => i?.id != 0).length > 0 ? themes : topics)
              .filter((i) => i?.id != 0)
              // .sort(thematicSort)
              .map((theme, key) => {
                let defOpened = themes.length == 1 && filterParams.thematics[0] != "0";
                return <ThematicAreaWrapper 
                    partnerList={partnerList}
                    thematicAreas={topics} 
                    filterParams={filterParams} 
                    key={`${key}-${defOpened}`} 
                    area={{...theme, children: theme?.children?.filter(a=>!(a.id+'')?.includes('-topic'))}} 
                    store={store} 
                    opened={defOpened} 
                  />;
              })}
        </GridTableWrapper>
      </>
    );
  };

  const onThematicChange = (e) => {
    const thematics = !e.thematics.length ? {thematics: ['0']} : e
      nestedSearch({ ...filterParams, ...thematics});
  };

  const onLocationChange = (newValue: []) => {
    if (themes.length) {
      nestedSearch({ ...filterParams, ...newValue })
    }
  };

  const onPartnersChange = (newValue: []) => {
    nestedSearch({ ...filterParams, ...newValue });
  };

  const nestedSearch = (params: FilterParams) => {
    setFilterParams(params);
    let str = [`${params.location.join(",")}`, `${params.thematics.join(",")}`, `${params.partners.join(",")}`, params.que].join("&");
    let str64 = base64Encode(str);
    history.push(routeDataCommonsConstants.THEMATIC_AREAS + "/" + str64);
  };

  let subUrl = base64Encode("Earth&" + area?.id + "#" + subtheme?.id + "&all");
  
  return (
    <>
      <AppLayoutContent>
        <div id="top"></div>
        {topics.length > 0 ? (
          <>
          <Layout className="container">
                <AppBreadcrumb
                  items={[
                    { name: "Thematic Areas", to: url },
                    { name: subtheme && area?.label, to: `${url}/${area?.id}` },
                    { name: indicator && subtheme?.label, to: `${url}/${subUrl}` },
                  ].filter((i) => i.name)}
                >
                  {indicator ? indicator?.indicator_name : subtheme ? subtheme.label : area?.label}
                </AppBreadcrumb>
              </Layout>

            <OpenKeysProvider>
              <Layout style={{ height: "100%", flexGrow: 1, flexDirection: "row" }}>
                {filterPosition == "left" && <FilterOptions position={filterPosition} />}
                <Layout style={{ overflow: "auto", backgroundColor: "#f2f2f2" }}>
                  <AreaBody className="container">
                    <>
                      <ThematicSearch/>
                      {contentPage.page=='explore' && <ExploreSubTheme partnerList={partnerList} filterParams={filterParams} data={contentPage.data}/>}
                      {contentPage.page=='carousel' && <SubThemeCarousel partnerList={partnerList} filterParams={filterParams} data={contentPage.data}/>}
                      {!['explore', 'carousel'].includes(contentPage.page) && params.dcid != 0 && !indicator && theme && !subtheme && getTopics()}
                    </>
                    <div className="footnote">
                      <Footnotes />
                    </div>
                  </AreaBody>
                </Layout>
              </Layout>
            </OpenKeysProvider>
          </>
        ) : loading ? (
          <ContentCard>
            <Spinner />
          </ContentCard>
        ) : null}
      </AppLayoutContent>
    </>
  );
};

export default ThematicAreaView;

export const menu = (setFilterPosition) => (
  <FilterMenu
    onClick={(info) => {
      setFilterPosition(info.key);
    }}
  >
    <Menu.ItemGroup title="Filter layout options">
      <Menu.Item
        className="hidden-mobile"
        key="left"
        icon={
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g clip-path="url(#clip0_34089_45757)">
              <path
                d="M17.5002 2.5H2.50016C1.5835 2.5 0.833496 3.25 0.833496 4.16667V15.8333C0.833496 16.75 1.5835 17.5 2.50016 17.5H17.5002C18.4168 17.5 19.1668 16.75 19.1668 15.8333V4.16667C19.1668 3.25 18.4168 2.5 17.5002 2.5ZM17.5002 15.8333H2.50016V4.16667H17.5002V15.8333ZM3.3335 5H5.8335V15H3.3335V5Z"
                fill="#7C7C7C"
              />
            </g>
            <defs>
              <clipPath id="clip0_34089_45757">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        }
      >
        Sidebar filters
      </Menu.Item>
      <Menu.Item
        key="top"
        icon={
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g clip-path="url(#clip0_34090_61616)">
              <path
                d="M17.5002 2.5H2.50016C1.5835 2.5 0.833496 3.25 0.833496 4.16667V15.8333C0.833496 16.75 1.5835 17.5 2.50016 17.5H17.5002C18.4168 17.5 19.1668 16.75 19.1668 15.8333V4.16667C19.1668 3.25 18.4168 2.5 17.5002 2.5ZM17.5002 15.8333H2.50016V4.16667H17.5002V15.8333ZM4.16683 5H15.8335V7.5H4.16683V5Z"
                fill="#7C7C7C"
              />
            </g>
            <defs>
              <clipPath id="clip0_34090_61616">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        }
      >
        Top bar filters
      </Menu.Item>
      <Menu.Item
        key="none"
        icon={
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g clip-path="url(#clip0_34090_61620)">
              <path
                d="M10.0002 5.41667C13.1585 5.41667 15.9752 7.19167 17.3502 10C15.9752 12.8083 13.1668 14.5833 10.0002 14.5833C6.8335 14.5833 4.02516 12.8083 2.65016 10C4.02516 7.19167 6.84183 5.41667 10.0002 5.41667ZM10.0002 3.75C5.8335 3.75 2.27516 6.34167 0.833496 10C2.27516 13.6583 5.8335 16.25 10.0002 16.25C14.1668 16.25 17.7252 13.6583 19.1668 10C17.7252 6.34167 14.1668 3.75 10.0002 3.75ZM10.0002 7.91667C11.1502 7.91667 12.0835 8.85 12.0835 10C12.0835 11.15 11.1502 12.0833 10.0002 12.0833C8.85016 12.0833 7.91683 11.15 7.91683 10C7.91683 8.85 8.85016 7.91667 10.0002 7.91667ZM10.0002 6.25C7.9335 6.25 6.25016 7.93333 6.25016 10C6.25016 12.0667 7.9335 13.75 10.0002 13.75C12.0668 13.75 13.7502 12.0667 13.7502 10C13.7502 7.93333 12.0668 6.25 10.0002 6.25Z"
                fill="#7C7C7C"
              />
            </g>
            <defs>
              <clipPath id="clip0_34090_61620">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        }
      >
        Hide filters
      </Menu.Item>
    </Menu.ItemGroup>
  </FilterMenu>
);

export const FilterBtn = ({ setFilterPosition }) => (
  <Dropdown overlay={() => menu(setFilterPosition)} trigger={["click"]}>
    <RadialButton onClick={(e) => e.preventDefault()}>
      <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368">
        <path d="M400-240v-80h160v80H400ZM240-440v-80h480v80H240ZM120-640v-80h720v80H120Z" />
      </svg>
    </RadialButton>
  </Dropdown>
);

export const base64Encode = (str: string) => {
  return btoa(unescape(encodeURIComponent(str)));
};

export const base64Decode = (encodedStr: string) => {
  return decodeURIComponent(escape(atob(encodedStr)));
};


export const serializeAreaParams = ({location, thematics, partners, que}:FilterParams) => {
  let str = [
      location.join(","), 
      thematics?.map(t=>(t as string)).join(","),
      partners?.map(t=>(t as string)).join(","),
      que
    ].join("&");
  return str;
};

const FilterMenu = styled(Menu)`
  @media(max-width: 768px){
    .hidden-mobile {
      display: none;
    }
  }
`

const AreaBody = styled.div`
  .subheader {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    color: #343434;
    margin: 2rem 1rem 1rem 1rem;
    padding-bottom: 0.5rem;
    display: block;
    @media (min-width: 992px) {
      margin: 19px 1.5rem 17px 1.5rem;
      padding-bottom: 0;
    }
  }
  > div {
    background: #fff !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .material-icons, 
  .material-icons-outlined, 
  .material-icons, 
  svg {
    color: #7C7C7C!important;
    &:hover {
      color: #1E1E1E!important;
    }
  }
  .footnote {
    padding: 1rem;
    margin: 0 0 3.5rem 0;
  }
`;

export const SubAreacard = styled.div`
  background: #fff;
  position: relative;
  border-radius: 8px;
  .block-header {
    padding: 16px 16px 0;
  }
  @media (min-width: 992px) {
    padding: 0.5rem;
  }
  .no-data-class {
    padding: 1rem;
  }

  datacommons-bar::part(container),
  datacommons-line::part(container),
  datacommons-map::part(container) {
    margin: 0px !important;
    width: 100%;
    display: inline-block;
  }
  datacommons-highlight::part(container) {
    margin: 0 !important;
    width: calc(100% + 0px);
    display: inline-block;
  }
`;

export const ThematicIndicatorHeader = styled(IndicatorHeader)`
  background: #fff;
  margin: 0 0;
  .ant-row {
    border-bottom: solid 1px #939598;
    .ant-col:last-child {
      white-space: nowrap;
      display: flex;
      align-items: center;
      margin-right: 2rem;
    }
  }
  .ant-col {
    > .ant-btn {
      padding: 0.8rem 0 0 16px;
   }
    > span {
      margin-left: 0px;
      padding-left: 0;
      padding-top: .5rem;
      padding-bottom: .5rem;
  }
  .index-text {
    font-size: 12px;
    display: flex;
    height: 20px;
    align-items: center;
    > * {
      margin-left: 10px;
    }
    button {
      border: none;
      background: none;
      padding: 0 0;
      height: 20px;
    }
    > a {
      height: 20px;
    }
    .material-icons-outlined, .material-icons {
      font-size: 20px;
      color: #323232;
    }
  }
`;
export const GridTableWrapper = styled.div`
  .ant-collapse {
    background: transparent;
    border: none;
    border-top: none;
    border-bottom: 1px solid #d9d9d9;
    &:last-child {
      border-radius: 0 0 8px 8px;
      border-bottom: none;
      .ant-collapse-content {
        border-radius: 0 0 8px 8px;
      }
    }
  }
  margin: 0 1rem;
  > input.ant-input {
    border-radius: 20px !important;
    padding: 4px 16px;
  }
  .results-subheader {
    padding: 8px 0 16px 16px;
    font-size: 12px;
  }
`;
export const GridHeader = styled.div`
  > .ant-row {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    margin: 1rem 0 !important;
    > div {
      span {
        display: flex;
        white-space: nowrap;
      }
      display: flex;
      align-items: center;
      flex-grow: 1;
      &.filters {
        flex: none;
      }
    }
    .font-l {
      font-size: 19px;
    }
    .filters {
      .ant-col:first-child {
        line-height: 34px;
      }
    }
  }
`;


export const FilterWrapper = styled.div`
  @media (max-width: 992px) {
    .ant-col-1 {
      width: 50px;
      min-width: 50px;
    }
    .ant-col-6 {
      width: calc(100% - 50px);
      min-width: calc(100% - 50px);
    }
    .ant-col-8,
    .ant-col-15,
    .ant-col-12,
    .ant-col-9 {
      width: 100%;
      min-width: 100%;
    }
  }
  padding-top: 24px;
  > .ant-row.position-left {
    padding: 15px 20px;
    > .ant-col {
      width: 100%;
      flex: auto;
      max-width: 100%;
    }
  }
  .sidebar-title {
    padding: 10px 20px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--surface-surface-stroke, #ccc);
    .ant-btn {
      border: none;
      svg {
        width: 18px !important;
      }
    }
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-input,
  .react-dropdown-select {
    border-radius: 20px !important;
    border: solid #dadce0 1px !important;
  }
  .ant-select-selection-item {
    background: transparent;
    border-radius: 20px;
    padding: 0 8px;
    max-width: 100% !important;
  }
  .ant-select-selection-overflow-item {
    max-width: 98% !important;
  }
`;

const RadialButton = styled(Button)`
  padding: 4px;
  width: 32px;
  border-radius: 100%;
  > svg {
    margin-right: 0 !important;
  }
`;
export const BackButton = styled(Button)`
  height: 100%;
  border: none;
  &.ant-btn svg {
    margin-right: 0 !important;
    width: 16px !important;
  }
`;
